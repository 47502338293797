<template>
  <v-container>
    <About />
  </v-container>
</template>

<script>
// @ is an alias to /src
import About from "@/components/About.vue";

export default {
  name: "Home",
  components: {
    About,
  },
};
</script>
