<template>
  <v-card align="center">
    <v-img
      src="https://i.imgur.com/R8lsYLv.png"
      aspect-ratio="1"
      max-height="200"
      max-width="200"
    ></v-img>
    <br />
    <v-card-title style="display: block">
     
        <h3>Omi's Mystical Explorer!</h3>

        <br />
     
    </v-card-title>
    <v-card-text>
      <div>
        <p style="font-size: 18px">Mystic Indexer Stats:</p>
        <p style="font-size: 13px">Players Indexed: {{ stats.players }}</p>
        <p style="font-size: 13px">Items Indexed: {{ stats.items }}</p>
        <p style="font-size: 3px">Jo is gay</p>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import fetch from "node-fetch";
export default {
  name: "About",
  data() {
    return {
      stats: {
        items: 0,
        players: 0,
      },
    };
  },
  mounted: async function () {
    const response = await fetch("https://omi.systems/stats");

    this.stats = await response.json();

    //console.log(this.stats)
  },
};
</script>


<style scoped>
.v-card {
  padding: 40px;
}
</style>